import { axiosConfig } from './axios-config.js'

// 機構設定
export const announcement = {
  // 取得公告
  get: (token) => axiosConfig.get('/api/announcements/agency', token),

  // 更新公告讀取時間
  updateNotifiedAt: (data, token) => axiosConfig.put('/api/announcements/update-notified-at', data, token),

  // 更新所有未讀公告讀取時間
  updateAllNotifiedAt: (data, token) => axiosConfig.put('/api/announcements/update-all-notified-at', data, token)
}
